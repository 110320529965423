import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Action from "../Action/Action";
import { showSuccess, showError, ShowLoader, HideLoader } from "../util/Common";


const LogIn = () => {
    const [logInData, setLoginData] = useState({ username: '', password: '' });
    const navigate = useNavigate();

    const handleChange = (e) => {
        let fieldName = e.target.name;
        setLoginData(existingData => ({
            ...existingData,
            [fieldName]: e.target.value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        ShowLoader();
        const response = await Action.post('admin/login', logInData);
        if (response.statusCode == '200') {
            console.log('sdsddsd', response.data.isExist);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("name", response.data.isExist.name);
            localStorage.setItem("_id", response.data.isExist._id);
            showSuccess("Successfully Logged In");
            window.location.href = "/";
            HideLoader();
        } else {
            showError(response.message);
            HideLoader();;
        };
    };

    return (<>
    <div className="card">
        <div className="card-body">
            <div className="pt-4 pb-2">
                <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
            </div>
            <form className="row g-3 needs-validation" onSubmit={handleSubmit} >
                <div className="col-12">
                    <label htmlFor="yourUsername" className="form-label">Username</label>
                    <div className="input-group has-validation">
                        <span className="input-group-text" id="inputGroupPrepend">@</span>
                        <input type="email" onChange={handleChange} name="username" className="form-control" id="yourUsername" required="" />
                        <div className="invalid-feedback">Please enter your username.</div>
                    </div>
                </div>
                <div className="col-12">
                    <label htmlFor="yourPassword" className="form-label">Password</label>
                    <input type="password" onChange={handleChange} name="password" className="form-control" id="yourPassword" required="" />
                    <div className="invalid-feedback">Please enter your password!</div>
                </div>
                <div className="col-12">
                    <button className="btn btn-primary w-100" type="submit">Login</button>
                </div>
                <div className="col-12">
                    {/* <p className="small mb-0">Don't have account? <Link to='/signup' href="pages-register.html">Create an account</Link>
                    </p> */}
                </div>
            </form>
        </div>
        </div>
    </>)
};

export default LogIn;