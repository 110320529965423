
import { useEffect, useState } from "react";
import Action from "../Action/Action";
import { HideLoader, shortenText, ShowLoader } from '../util/Common';
import { AiFillEdit, AiFillDelete } from 'react-icons/ai';
import { Link, useNavigate } from "react-router-dom";
import settings from "../util/Config";
import Toggle from 'react-toggle';
import { ShowImage } from "../util/Common";
import ReactPaginate from 'react-paginate';


const Backend_Url = settings.backendUrl;

const Blogs = () => {

    const [response, setResponse] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const navigate = useNavigate();
    const [paginationData, setPaginationData] = useState({
        docs: [],
        hasNextPage: false,
        hasPrevPage: false,
        limit: 0,
        offset: 0,
        page: 0,
        pagingCounter: 0,
        totalDocs: 0,
        totalPages: 0,
    });

    const fetchData = async (pageNumber = 1) => {
        ShowImage();
        const response = await Action.get(`admin/getBlog?pageNumber=${pageNumber}`);
        if (response) {
            console.log(response);
            setPaginationData(response.data);
            HideLoader();
        } else {
            HideLoader();
            console.log('error');
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handlePageClick = (e) => {
        let newPageN = e.selected + 1;
        setPageNum(newPageN);
        fetchData(newPageN);
    };

    const handleIsActive = async (e, el) => {
        e.preventDefault();
        console.log(e.target.checked);
        let data = {};
        data["_id"] = el._id;
        data["isActive"] = e.target.checked;
        data["type"] = "blog";
        ShowLoader();
        const response = await Action.put('admin/markactive', data);
        if (response) {
            fetchData(pageNum);
            HideLoader();
        } else {
            console.log('error');
            HideLoader();
        };
    };

    return (<>
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6">
                        <h5 className="card-title">Blog's Table</h5>
                    </div>
                    <div className="col-md-6 text-md-end">
                        <Link to='/addBlog' type="button" class="btn btn-primary rounded-pill">Add Blog</Link>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Article Title</th>
                                <th scope="col">Description</th>
                                <th scope="col">Image</th>
                                <th scope="col">Is Active</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        {paginationData.docs.map((items) => (
                            <tbody>
                                <tr>
                                    <td>{items.ArticleTitle}</td>
                                    <td>{shortenText(items.ArticleBody)}</td>
                                    <img src={ShowImage(items.imageUrl)} alt="" width="200" height="100"></img>
                                    <td class="text-center chkbox">
                                        <div class="form-check pl-0">
                                            <label class="switch">
                                                <input type="checkbox" data-toggle="toggle" checked={items.isActive} onChange={(e) => handleIsActive(e, items)} data-onstyle="dark" />
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </td>
                                    <td>
                                        <AiFillEdit onClick={() => navigate(`/blog/${items._id}`)} ></AiFillEdit>
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
            </div>
        </div>
        {paginationData.docs.length > 0 ?
            <div className="react-paginate-wrapper"><ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={paginationData?.totalPages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={
                    "pagination justify-content-center"
                }
                forcePage={paginationData?.page - 1}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
            /></div>
            : <div className="text-center">No Data</div>}
    </>)
};
export default Blogs;