import { useNavigate, useParams } from 'react-router-dom';
import Action from "../Action/Action";
import react, { useEffect, useState } from 'react';
import { HideLoader, showError, ShowLoader, showSuccess } from '../util/Common';

const ChangePassword = () => {
    const navigator = useNavigate();
    const [form, setForm] = useState({ oldPassWord: "", newPassword: "", confirmPassword: "" });
    const id = localStorage.getItem('_id');

    const handleChange = e => {
        const { name, value } = e.target;
        setForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        ShowLoader();
        const response = await Action.post(`admin/${id}/users`, form);
        if (response.statusCode == 200) {
            navigator('/blogs');
            showSuccess(response.message);
            HideLoader();
        } else {
            showError(response.message);
            HideLoader();
        };
    };

    return (<>
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Change Password</h5>
                <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-2 col-form-label">Old Password</label>
                        <div className="col-sm-10">
                            <input type="text" onChange={handleChange} defaultValue={form?.oldPassWord} name='oldPassWord' className="form-control" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label htmlFor="inputPassword" className="col-sm-2 col-form-label">New Password </label>
                        <div className="col-sm-10">
                            <input name='newPassword' defaultValue={form?.newPassword} onChange={handleChange} className="form-control"></input>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label htmlFor="inputNumber" className="col-sm-2 col-form-label">Confirm Password</label>
                        <div className="col-sm-10">
                            <input name='confirmPassword' defaultValue={form?.confirmPassword} onChange={handleChange} className="form-control"></input>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-sm-10">
                            <button type="submit" className="btn btn-primary">Change Password</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>)
};

export default ChangePassword;