import { useNavigate, useParams } from 'react-router-dom';
import Action from "../Action/Action";
import react, { useEffect, useState } from 'react';
import { HideLoader, showError, ShowLoader, showSuccess } from '../util/Common';
import moment from 'moment'


const AddEvent = () => {
    const navigator = useNavigate();
    const [form, setForm] = useState({ EventTitle: "", Description: "", Date : "" , Time: ""});
    const [image, setImage] = useState('');
    const [media, setMedia] =useState([])
    const { id } = useParams();

    const handleChange = e => {
        const { name, value } = e.target;
        console.log('imput', name, value);
        setForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleImage = e => {
        setImage(e.target.files[0]
        );
    };
    const handleMedia = (e) => {
        setMedia(e.target.files);
    }

    useEffect(() => {
        if (id) {
            getData();
        };
    }, []);

    const getData = async () => {
        ShowLoader();
        const response = await Action.getById(`admin/getEventById/${id}`);

        if (response) {
            setForm(response.data);
            setImage(response.data.imageUrl);
            setMedia(response.data.media)
            HideLoader();
        } else {
            console.log('asaS');
            HideLoader();
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.delete("image")
        formData.delete("featherImages")

        formData.append('EventTitle', form?.EventTitle);
        formData.append('publishedBy', localStorage.getItem('id'));
        formData.append('Description', form?.Description);
        formData.append('Time', '00:00');
        formData.append('Date', form?.Date);
        formData.append('image', image);
        
        for (let i = 0; i < media?.length; i++) {
            formData.append("Media", media[i]);
          } 
      console.log('click');
        if (id) {
            ShowLoader();
            const response = await Action.put(`admin/edit/event/${id}`, formData);
            if (response.statusCode == 200) {
                navigator('/events');
                showSuccess(response.message);
                HideLoader();
            } else {
                showError(response.message);
                HideLoader();
            };
        } else {
            ShowLoader();
            const response = await Action.post(`admin/addEvent`, formData);
            if (response.statusCode == 200) {
                navigator('/events');
                showSuccess(response.message);
                HideLoader();
            } else {
                showError(response.message);
                HideLoader();
            };
        };
    };

    return (<>
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Event's Details</h5>
                <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-2 col-form-label">EventTitle</label>
                        <div className="col-sm-10">
                            <input type="text" onChange={handleChange} defaultValue={form?.EventTitle} name='EventTitle' className="form-control" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Description </label>
                        <div className="col-sm-10">
                            <textarea name='Description' defaultValue={form?.Description} onChange={handleChange} className="form-control"></textarea>
                        </div>
                    </div>
                    <div className="row mb-3">
                        {console.log('console', moment(form?.Date).format("yyyy-MM-DD"))}
                        <label htmlFor="inputText" className="col-sm-2 col-form-label">Date</label>
                        <div className="col-sm-10">
                            <input type="date" onChange={handleChange} value={ moment(form?.Date).format("yyyy-MM-DD")} name='Date' className="form-control" />
                        </div>
                    </div>
                    {/* <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-2 col-form-label">Time</label>
                        <div className="col-sm-10">
                            <input type="time" onChange={handleChange} defaultValue={form?.Time} name='Time' className="form-control" />
                        </div>
                    </div> */}


                    <div className="row mb-3">
                        <label htmlFor="inputNumber" className="col-sm-2 col-form-label">Image</label>
                        <div className="col-sm-10">
                            <input className="form-control" defaultValue={image} onChange={handleImage} name='image' type="file" id="formFile" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label htmlFor="inputNumber" className="col-sm-2 col-form-label">Media</label>
                        <div className="col-sm-10">
                            <input className="form-control" defaultValue={media}  multiple onChange={handleMedia} name='media' type="file" id="formFile" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-sm-10">
                            <button type="submit" className="btn btn-primary">   {id ? 'Update' : 'Submit'}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>)
};


export default AddEvent;