import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Auth from './Layout/Auth/index';
import LogIn from './Pages/Login';
import Backend from './Layout/Backend/index';
import Dashboard from './Pages/Dashboard';
import User from './Pages/User';
import EditUser from './Pages/UserForm';
import Resource from './Pages/Resource';
import EditResource from './Pages/ResourceForm';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import React, { Component } from "react";
import "react-toggle/style.css";
import Blogs from './Pages/Blogs';
import EditBlog from './Pages/BlogForm';
import Experts from './Pages/Experts.js';
import EditExpert from './Pages/ExpertForm';
import UserView from './Pages/User.View';
import ChangePassword from './Pages/Change.password';
import AddEvent from './Pages/EevntForm';
import Event from './Pages/Event';

function App() {
  return (
    <>
      <div id="page-loader">
        <div id="page-loader-inner">
          <img src='/loader.gif' alt="" />
        </div>
      </div>

      <ToastContainer />
      {localStorage.getItem("token") ?

        <Routes>
          <Route element={<Backend />}>
            <Route path='*' element={<Dashboard />} />
            <Route path='/' element={<Dashboard />} />
            <Route path='/user' element={<User />} />
            <Route path='/user/:id' element={<EditUser />} />
            <Route path='/userview/:id' element={<UserView />} />
            <Route path='/adduser' element={<EditUser />} />
            <Route path='/Blog/:id' element={<EditBlog />} />
            <Route path='/addBlog' element={<EditBlog />} />
            <Route path='/resource' element={<Resource />} />
            <Route path='/resource/:id' element={<EditResource />} />
            <Route path='/add-resource' element={<EditResource />} />
            <Route path='/blogs' element={<Blogs />} />
            <Route path='/experts' element={<Experts />} />
            <Route path='/experts/:id' element={<EditExpert />} />
            <Route path='/addexperts' element={<EditExpert />} />
            <Route path='/changepassword' element={<ChangePassword />} />
            <Route path='/addevents' element={<AddEvent />} />
            <Route path='/events' element={<Event />} />
            <Route path='/event/:id' element={<AddEvent />} />

          </Route>
        </Routes> :
        <Routes>
          <Route element={<Auth />}>
            <Route path='*' element={<LogIn />} />
            <Route path='/login' element={<LogIn />} />
          </Route>
        </Routes>
      }
    </>
  );
}

export default App;
