import react, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Action from "../Action/Action";
import { HideLoader, ShowLoader } from '../util/Common';

const EditUser = () => {
    const navigate = useNavigate();
    const [experience, setExperience] = useState('');
    const [form, setForm] = useState({ name: "", username: "", experience: "", resource: "", bio: "", image: "" });
    const { id } = useParams();
    const [resources, setResources] = useState([]);
    const [resource, setResource] = useState('');
    const [password, setPassword] = useState('');
    const [image, setImage] = useState('');
    const [experiences, setExperiences] = useState([
        '1 Year',
        '2 Years',
        '3 Years',
        '4 Years',
        '5 Years',
        '6 Years',
        '7 Years',
        '8 Years',
        '9 Years',
        '10+ Years',
    ]);

    const handleChange = e => {
        const { name, value } = e.target;
        setForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleImage = e => {
        setImage(e.target.files[0]
        );
    };

    const getResources = async () => {
        ShowLoader();
        const response = await Action.get('user/resources');
        if (response) {
            console.log(response.data);
            setResources(response.data);
            HideLoader();
        } else {
            console.log('error');
            HideLoader();
        };
    };

    const hanlePassword = (e) => {
        setPassword(e.target.value);
    };

    useEffect(() => {
        if (id) {
            getData();
        };
    }, []);

    const getData = async () => {
        ShowLoader();
        const response = await Action.getById(`user/${id}/user`);
        if (response) {
            console.log(response);
            setForm(response.data);
            setExperience(response.data.experience);
            setResource(response.data.resource._id);
            setImage(response.data.imageUrl);
            HideLoader();
        } else {
            console.log('error');
            HideLoader();
        };
    };

    useEffect(() => {
        getResources();
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', form.name);
        formData.append('username', form.username);
        formData.append('image', image);
        formData.append('bio', form.bio);
        formData.append('experience', experience);
        formData.append('resource', resource);
        <>
            {
                id ? '' :
                    formData.append('password', password)
            };
        </>
        if (id) {
            ShowLoader();
            const response = await Action.put(`admin/${id}/uers`, formData);
            if (response.statusCode == 200) {
                toast(response.message);
                navigate('/user');
                HideLoader();
            } else {
                toast(response.message);
                HideLoader();
            }
        } else {
            ShowLoader();
            const response = await Action.post('admin/uers', formData);
            if (response.statusCode == 200) {
                toast(response.message);
                navigate('/user');
                HideLoader();
            } else {
                toast(response.message);
                HideLoader();
            };
        };
    };

    return (<>
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">User's Details </h5>
                <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-2 col-form-label">Name</label>
                        <div className="col-sm-10">
                            <input type="text" onChange={handleChange} defaultValue={form.name} name='name' placeholder="Enter a Name" className="form-control" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label htmlFor="inputEmail" className="col-sm-2 col-form-label">User Name</label>
                        <div className="col-sm-10">
                            <input type="text" onChange={handleChange} defaultValue={form.username} name='username' placeholder="Email/Phone No."
 className="form-control" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label htmlFor="inputNumber" className="col-sm-2 col-form-label"  >Experience</label>
                        <div className="col-sm-10">
                            <div className="form-group">
                                <select name="experience" type="experience" className="form-control" onChange={(e) => setExperience(e.target.value)}>
                                    <option value="">Select Experience</option>
                                    {experiences.map((item) => {
                                        return <>
                                            <option selected={experience == item} value={item}>{item}</option>
                                        </>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="col-sm-2 col-form-label">Resource</label>
                        <div className="col-sm-10">
                            <div className="form-group">
                                <select name="resource" type="Resource" className="form-control" onChange={(e) => setResource(e.target.value)}>
                                    <option value="">Select Resource</option>
                                    {resources.map((item) => {
                                        return <>
                                            <option selected={resource == item._id} value={item._id}>{item.name}</option>
                                        </>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label htmlFor="inputPassword" className="col-sm-2 col-form-label"  >Bio</label>
                        <div className="col-sm-10">
                            <textarea name='bio' defaultValue={form.bio} onChange={handleChange} placeholder="Enter a Bio" className="form-control"></textarea>
                        </div>
                    </div>
                    {id ? '' :
                        <div className="row mb-3">
                            <label htmlFor="inputEmail" className="col-sm-2 col-form-label"  >Password</label>
                            <div className="col-sm-10">
                                <input type="password" onChange={hanlePassword} name='username'placeholder="Enter a Password" className="form-control" />
                            </div>
                        </div>
                    }
                    <div className="row mb-3">
                        <label htmlFor="inputNumber" className="col-sm-2 col-form-label">File Upload</label>
                        <div className="col-sm-10">
                            <input className="form-control" defaultValue={form.imageUrl} onChange={handleImage} name='image' type="file" id="formFile" />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-sm-10">
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </>)
};

export default EditUser;