import { Outlet } from "react-router-dom"



const Auth = () => {
    return (<>
        <main>
            <div class="container">
                <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                                <div class="d-flex justify-content-center py-4">
                                    <a href="index.html" class="logo d-flex align-items-center w-auto">
                                        <img src="/infiniteLogo.png" alt="" />
                                        <span class="d-none d-lg-block">Infinite</span>
                                    </a>
                                </div>
                                <div class="card mb-3">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    </>)
}

export default Auth