import axios from "axios";
import settings from "../util/Config";
const Backend_Url = settings.backendUrl;

const post = (url, payload) => {
  return new Promise((resolve) => { 
	axios
		.post(`${Backend_Url}/${url}`, payload)
		.then(response => {
			return resolve(response.data);
		})
		.catch(function (error) {
			console.error(error);
			return resolve(error)
		});
	})
}


const get = (payload) => {
	return new Promise((resolve) => {
		axios
			.get(`${Backend_Url}/${payload}`)
			.then(response => {
				console.log(response);
				return resolve(response.data);
			})
			.catch(function (error) {
				console.error(error);
				return resolve(null);
			});
	})
};

const getById = (payload) => {
	return new Promise((resolve) => {
		axios
			.get(`${Backend_Url}/${payload}`)
			.then(response => {
				return resolve(response.data);
			})
			.catch(function (error) {
				console.error(error);
				return resolve(null);
			});
	})
};

const put = (url , payload) => {
	return new Promise((resolve) => {
		axios.put(`${Backend_Url}/${url}`, payload)
		.then(response => {
			return resolve(response.data)
		})
		.catch((error) => {
			console.log(error)
			return resolve(null)
		})
	})
}



axios.interceptors.request.use(function (config) {
   
    config.headers.authorization =  localStorage.getItem("token");
	return config
  }, function (error) {
console.log(error)
  });


export default { get, post, getById, put }