import { useEffect, useState } from "react";
import Action from "../Action/Action";
import { HideLoader, shortenText, ShowImage, ShowLoader } from "../util/Common";
import { Link, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [data, setData] = useState({});
  const [users, setUsers] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    ShowLoader();
    let response = await Action.get('admin/stats');
    if (response) {
      setData(response.data);
      setUsers(response.data.usersData);
      HideLoader();
    } else {
      console.log('err');
      HideLoader();
    };
  };

  return (<>
    <div className="pagetitle">
      <h1>Dashboard</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            {/* <Link to='/' >Home</Link> */}
          </li>
          {/* <li className="breadcrumb-item active">Dashboard</li> */}
        </ol>
      </nav>
    </div>
    <section className="section dashboard">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-xxl-3 col-xl-12">
              <div className="card info-card customers-card">
                <div className="filter">
                  <a className="icon" href="#" data-bs-toggle="dropdown">
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <li className="dropdown-header text-start">
                      <h6>Filter</h6>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">Today</a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">This Month</a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">This Year</a>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Users
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-people-fill"></i>
                    </div>
                    <div className="ps-3">
                      <h6>{data.users}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-md-6">
              <div className="card info-card revenue-card">
                <div className="filter">
                  <a className="icon" href="#" data-bs-toggle="dropdown">
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <li className="dropdown-header text-start">
                      <h6>Filter</h6>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">Today</a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">This Month</a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">This Year</a>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Blogs
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-card-list"></i>
                    </div>
                    <div className="ps-3">
                      <h6>{data.blogs}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-12">
              <div className="card info-card customers-card">
                <div className="filter">
                  <a className="icon" href="#" data-bs-toggle="dropdown">
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <li className="dropdown-header text-start">
                      <h6>Filter</h6>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">Today</a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">This Month</a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">This Year</a>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Experts
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-person-circle"></i>
                    </div>
                    <div className="ps-3">
                      <h6>{data.experts}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-md-6">
              <div className="card info-card sales-card">
                <div className="filter">
                  <a className="icon" href="#" data-bs-toggle="dropdown">
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <li className="dropdown-header text-start">
                      <h6>Filter</h6>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">Today</a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">This Month</a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">This Year</a>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Resources
                  </h5>
                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-list-stars"></i>
                    </div>
                    <div className="ps-3">
                      <h6>{data.resources}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="pt-3 pl-2 pr-2">
                <h6>Last 10 Registered Users</h6>
              </div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Resource</th>
                    <th scope="col">Experience</th>
                    <th scope="col">Email/Phone No.</th>
                    <th scope="col">Bio</th>
                    <th scope="col">Image</th>
                  </tr>
                </thead>
                {users.map((items) => (
                  <tbody>
                    <tr>
                      <td>{items.name}</td>
                      <td>{items.resource.name}</td>
                      <td>{items.experience}</td>
                      <td>{(items.username)}</td>
                      <td>{shortenText(items.bio)}</td>
                      <img src={ShowImage(items.imageUrl)} alt="" width="200" height="100"></img>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>)
};

export default Dashboard;