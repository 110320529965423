
import { Link, useNavigate } from 'react-router-dom'

const SideBar = () => {
    const navigate = useNavigate()

    const handleLogOut = (e) => {
        e.preventDefault();
        localStorage.removeItem("token")
        localStorage.removeItem("_id")
        window.location.href = "/login";

    }


    return (<>
        <aside id="sidebar" className="sidebar">
            <ul className="sidebar-nav" id="sidebar-nav">
                <li className="nav-item">
                    <Link to='/' className="nav-link " >
                        <i className="bi bi-grid"></i>
                        <span>Dashboard</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to='/user' className="nav-link " >
                        <i class="bi bi-people-fill"></i>
                        <span>Users</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to='/resource' className="nav-link " >
                        <i class="bi bi-list-stars"></i>
                        <span>Resources</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to='/blogs' className="nav-link " >
                        <i class="bi bi-card-list"></i>
                        <span>Blogs</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to='/events' className="nav-link " >
                        <i class="bi bi-card-list"></i>
                        <span>Events</span>
                    </Link>
                </li>


                <li className="nav-item">
                    <Link to='/experts' className="nav-link " >
                        <i class="bi bi-person-circle"></i>
                        <span>Experts</span>
                    </Link>
                </li>


                <li className="nav-item">
                    <Link to='/changepassword' className="nav-link " >
                        <i class="bi bi-shield-lock-fill"></i>
                        <span>Change Password</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <a href={undefined} onClick={handleLogOut} className="nav-link " >
                        <i class="bi bi-box-arrow-right"></i>
                        <span>LogOut</span>
                    </a>
                </li>




            </ul>
        </aside>
    </>)
}

export default SideBar