import { Outlet } from "react-router-dom"
import Footer from "./Footer"
import Header from "./Header"
import SideBar from "./SideBar"


const Backend = () => {
    return (<>
        <Header></Header>
        <SideBar></SideBar>
        <main id="main" className="main">
            <Outlet />
        </main>
        <Footer></Footer>
        <a href="#" className="back-to-top d-flex align-items-center justify-content-center">
            <i className="bi bi-arrow-up-short"></i>
        </a>


    </>)
}

export default Backend