import { toast } from 'react-toastify';
import settings from './Config';
var backendUrl = settings.backendUrl;

export const notEmpty = (textString) => {
    if (textString != null && textString !== '' && textString !== undefined && textString !== 'undefined' && textString !== 'null') {
        return true;
    }
    return false;
};

export const shortenText = (textString, length = 50) => {
    if (notEmpty(textString)) {
        textString = textString.toString();
        if (textString.length > length) {
            return (textString.slice(0, 50) + '...');
        } else {
            return textString;
        };
    }
    return textString;
};

export const showSuccess = (message) => {
    toast.success(message);
};

export const showError = (message) => {
    toast.error(message, {
        theme: "colored",
    });
};

export const ShowImage = (url, text = 'Infinite Communities') => {
    if (String(url).slice(0, 4) == 'http') {
        return url;
    } else if (url) {
        return `${backendUrl}/${url}`
    } else {
        return `https://via.placeholder.com/450x300/cccccc/333333?text=${text}`;
    };
};

export const ShowLoader = () => {
    const element = document.getElementById("page-loader");
    if (element != null) {
        element.classList.add("show");
    };
};

export const HideLoader = () => {
    const element = document.getElementById("page-loader");
    if (element != null) {
        element.classList.remove("show");
    };
};