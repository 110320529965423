import react, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Action from "../Action/Action";
import { HideLoader, shortenText, showError, ShowImage, ShowLoader, showSuccess } from '../util/Common';

const UserView = () => {
    const [form, setForm] = useState({ name: "", username: "", experience: "", resource: "", bio: "", image: "" });
    const { id } = useParams();
    const [portfolio, setPortfolios] = useState([]);

    const handleChange = e => {
        const { name, value } = e.target;
        setForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        if (id) {
            getData();
            portfolios();
        };
    }, []);

    const getData = async () => {
        ShowLoader();
        const response = await Action.getById(`user/${id}/user`);
        if (response) {
            console.log(response);
            setForm(response.data);
            HideLoader();
        } else {
            showError('Users Data Not found');
            HideLoader();
        };
    };

    const portfolios = async () => {
        ShowLoader();
        const response = await Action.getById(`user/${id}/portfolio`);
        if (response) {
            console.log('sdsdsd', response);
            setPortfolios(response.data);
            HideLoader();
        } else {
            showError('Users Data Not found');
            HideLoader();
        };
    };

    return (<>
        <div className='card'>
            <div className="card-body ">
                <h5 className="card-title">User's Detail </h5>
                <div className="row mb-3">
                    <label htmlFor="inputText" className="col-sm-2 col-form-label">Name</label>
                    <div className="col-sm-10">
                        {form.name}
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="inputEmail" className="col-sm-2 col-form-label">User Name</label>
                    <div className="col-sm-10">
                        {form.username}
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="inputNumber" className="col-sm-2 col-form-label">Experience</label>
                    <div className="col-sm-10">
                        {form.experience}
                    </div>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-2 col-form-label">Resource</label>
                    <div className="col-sm-10">
                        {form.resource.name}
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Bio</label>
                    <div className="col-sm-10">
                        {form.bio}
                    </div>
                </div>
                <div className="row mb-3">
                    <label htmlFor="inputNumber" className="col-sm-2 col-form-label">{form.name}'s Image</label>
                    <div className="col-sm-10">
                        <img src={ShowImage(form.imageUrl)} alt="BigCo Inc. logo" width="700" height="500" />
                    </div>
                </div>
            </div>
        </div>
        <div className='card'>
            <div className="card-body">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Project Name</th>
                            <th scope="col">Description</th>
                            <th scope="col">Image</th>
                        </tr>
                    </thead>
                    {portfolio.map((items) => (
                        <tbody>
                            <tr>
                                <td>{items.projectName}</td>
                                <td>{shortenText(items.description)}</td>
                                <img src={ShowImage(items.image)} alt="" width="200" height="100"></img>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
        </div>
    </>)
};

export default UserView;