import { useNavigate, useParams } from 'react-router-dom';
import Action from "../Action/Action";
import react, { useEffect, useState } from 'react';
import { HideLoader, showError, ShowLoader, showSuccess } from '../util/Common';


const EditResource = () => {
    const navigator = useNavigate();
    const [form, setForm] = useState({ name: "", description: "" });
    const [image, setImage] = useState('');
    const { id } = useParams();

    const handleChange = e => {
        const { name, value } = e.target;
        setForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleImage = e => {
        setImage(
            e.target.files[0]
        );
    };

    useEffect(() => {
        if (id) {
            getData();
        };
    }, []);

    const getData = async () => {
        ShowLoader();
        const response = await Action.getById(`user/resources/${id}`);
        if (response) {
            console.log('asaS', response);
            setForm(response.data);
            setImage(response.data.imageUrl);
            HideLoader();
        } else {
            console.log('error');
            HideLoader();
        };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', form.name);
        formData.append('description', form.description);
        formData.append('image', image); //: formData.append('image', form.image) 
        if (id) {
            ShowLoader();
            const response = await Action.put(`user/resource/${id}`, formData);
            if (response.statusCode == 200) {
                navigator('/resource');
                showSuccess(response.message);
                HideLoader();
            } else {
                showError(response.message);
                HideLoader();
            }
        } else {
            ShowLoader();
            const response = await Action.post(`user/resources`, formData);
            if (response.statusCode == 200) {
                navigator('/resource');
                showSuccess(response.message);
                HideLoader();
            } else {
                showError(response.message);
                HideLoader();
            };
        };
    };

    return (<>
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Resource's Details</h5>
                <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                        <label htmlFor="inputText" className="col-sm-2 col-form-label">Name</label>
                        <div className="col-sm-10">
                            <input type="text" onChange={handleChange} defaultValue={form?.name} name='name' className="form-control" />
                        </div>
                    </div>


                    <div className="row mb-3">
                        <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Description</label>
                        <div className="col-sm-10">
                            <textarea name='description' defaultValue={form?.description} onChange={handleChange} className="form-control"></textarea>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="inputNumber" className="col-sm-2 col-form-label">Image</label>
                        <div className="col-sm-10">
                            <input className="form-control" defaultValue={image} onChange={handleImage} name='image' type="file" id="formFile" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-sm-10">

                            <button type="submit" className="btn btn-primary">   {id ? 'Update' : 'Submit'}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </>)
};


export default EditResource;