import { Link } from "react-router-dom"



const Header = () => {
    return (<>
        <header id="header" className="header fixed-top d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-between">
                <Link to='/'  className="logo d-flex align-items-center admin-logo">
                    <img src="/infiniteLogo.png" alt="" />
                    <span className="d-none d-lg-block">Infinite</span>
                </Link>
            </div>
           
            <nav className="header-nav ms-auto">
                <ul className="d-flex align-items-center">
                    <li className="nav-item d-block d-lg-none">
                        <a className="nav-link nav-icon search-bar-toggle " href="#">
                            <i className="bi bi-search"></i>
                        </a>
                    </li>
                  
                   
                    <li className="nav-item dropdown pe-3">
                        <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                            {/* <img src="/assets/img/profile-img.jpg" alt="Profile" className="rounded-circle" /> */}
                            {/* <span className="d-none d-md-block dropdown-toggle ps-2">{localStorage.getItem("name")}</span> */}
                        </a>
                    
                    </li>
                </ul>
            </nav>
        </header>
    </>)
}


export default Header